<template>
  <t-page>
    <template #header>
      <h3>{{ $t('fields.employees') }}</h3>
    </template>
    <t-grid>
      <t-grid-cell>
        <index-table :query="query" :fields="fields" :filters="filters" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import Employee from '~/models/Employee';
import IndexTable from '~/pages/shared/employees/partials/IndexTable';

export default Vue.extend({
  components: { IndexTable },

  middleware: 'tenantmember',

  data: () => ({
    query: () => new Employee().include('user', 'organization'),

    filters: [
      // 'has_organization',
    ],

    fields: [
      'fullname',
      'email',
      'meta.phonenumber',
      'meta.position',
      'organization',
      'created_at',
    ],
  }),
});
</script>
